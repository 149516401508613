<script>
import { monthNames }  from '@/helpers'

export default {
  components: {
   
  },
  props: {

    noCard: {
      type: Boolean,
      required: false
    },
    instrument: {
      type: Object,
      required: true
    },
    normalized:{
      type: Boolean,
      required: true
    }
  },
  created(){
 
  },
  data() {
    return {
      monthNames: [...monthNames],
      secondColor: "#6183e8"
    };
  },
  methods:{
    values(child) {
      return this.normalized ? child.data.mainStatistic.normalizedValues  || {}: child.data.mainStatistic.values || {};
    }
  },
  computed:{
    liveMonthlyPerformanceYear() {
      const charts = this.normalized ?  this.instrument.data.normalizedNetAssetValueGrowthChart || [] : this.instrument.data.netAssetValueGrowthCharts || [];
      const liveChart = charts.find(x => x.isLive);
      return (liveChart || {}).year || 9999;
    },
    tableModel() {
      let cells = {};
      let rows = [...new Set((this.monthlyPerformance).map(x => x.year.toString()))];
      let monthlyPerfColumnNames = [...new Set((this.monthlyPerformance).map(x => this.monthNames[x.month-1]))];
      rows.sort((a, b) => a.localeCompare(b));
      rows.reverse();
      //debugger; // eslint-disable-line no-debugger
      rows.forEach(rowName =>{
        const rowCells = this.monthlyPerformance.filter(x => x.year.toString() === rowName);
        let a = {};
        cells[rowName] = a;
        monthlyPerfColumnNames.forEach(columnName =>{
        
          const cellItems = rowCells.filter(x =>  this.monthNames[x.month-1] === columnName) || [];
          const c8Value = cellItems.find(x => x.name === 'C8' || x.name === (this.instrument || {}).name) || {};
          const notC8Value = cellItems.find(x => x.name !== 'C8' && x.name !== (this.instrument || {}).name) || {};
          a[columnName] = {
            base: c8Value.value,
            additional: notC8Value.value
          };
        });
      });
    
      return {
        columns:  monthNames.filter(x => x !== 'YTD'),
        rows: rows,
        cells: cells
      };
    },
    monthlyPerformance () {
      return this.normalized ?  this.instrument.data.performance.normalizedMonthly || [] : this.instrument.data.performance.monthly || [];
    }
  }
};
</script>

<template>
 <div :class="{'card cardc8': !noCard}">
    <div  :class="{'card-content': !noCard}">
      <div  :class="{'card-body': !noCard}">

        <!-- Header -->
        <div class="row" v-if="!noCard">
          <div class="col">
            <h5 class="card-title">Monthly performance</h5>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table table-centered table-hover  nowrap">
            <thead>
              <tr>
                <th style="max-width:60px;width:60px; "></th>
                <th v-for="(cName, $index) in tableModel.columns" :key="$index" style="text-align: right;"><b>{{cName}}</b></th>
                <th  style="max-width:100px;width:100px;text-align: right; "><b>YTD</b></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(rName, $rIndex) in tableModel.rows" :key=" $rIndex">
                <td style="max-width:60px;width:60px; text-align: center;">{{rName}}</td>
                <td v-for="(cName, $index) in tableModel.columns" :key="$index" style="text-align: right;">
                  <div v-if="liveMonthlyPerformanceYear && liveMonthlyPerformanceYear <= rName" style="color:#7d096d;font-family: sans-serif;font-weight: 700">
                    {{(tableModel.cells[rName][cName] || {}).base}}
                    <div :style="{'color': secondColor}" v-if="(tableModel.cells[rName][cName] || {}).additional">
                        {{(tableModel.cells[rName][cName] || {}).additional}}
                    </div>
                  </div>
                  <div v-if="!liveMonthlyPerformanceYear || liveMonthlyPerformanceYear > rName">
                    {{(tableModel.cells[rName][cName] || {}).base}}
                    <div :style="{'color': secondColor}" v-if="(tableModel.cells[rName][cName] || {}).additional">
                        {{(tableModel.cells[rName][cName] || {}).additional}}
                    </div>
                  </div>
                </td>
                <td style="max-width:100px;width:100px; text-align: right;">
                  {{(tableModel.cells[rName]['YTD'] || {}).base}}
                  <div :style="{'color': secondColor}" v-if="(tableModel.cells[rName]['YTD'] || {}).additional">
                      {{(tableModel.cells[rName]['YTD'] || {}).additional}}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="text-muted mt-4" v-if="instrument.data.performance && instrument.data.performance.monthlyPerformanceHint">
          <span v-html="instrument.data.performance.monthlyPerformanceHint"></span>
        </div>
      </div>
    </div>
  </div>
</template>