<script>
import Layout from "../../layouts/horizontal";
import { mapGetters, mapActions } from 'vuex';
import { chartColors } from '@/helpers'

import MainStat from './components/main-stat'
import Performance from './components/performance'
//import Composition from './components/composition'
import MonthlyPerformance from './components/monthly-performance'
// import Weights from './components/weights'
// import LastCorrelation from './components/last-correlation'
// import AllCorrelation from './components/all-correlation'
// import CrisesChart from './components/crises-chart'

export default {
  components: {
    Layout,
    MainStat,
    Performance,
   // Composition,
    MonthlyPerformance,
    // Weights,
    // LastCorrelation,
    // AllCorrelation,
    // CrisesChart
  },
  props: {
    instrumentId: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      colorIndex: 0,
      colors: {},
      title: 'Loading...',
      initializing: true,
      item: null,
      breadcrumbs: [],
      normalised: false,
      pdfProcessing: false
    };
  },
  created() {
    this.init();  
  },
  computed: {
    ...mapGetters('instruments', {
      loading: 'loading'
    }),
    isBusy (){
      return this.loading;
    }
  },
  methods: {
    ...mapActions('instruments', {
      loadInstrument: 'loadInstrument'
    }),
    generatorColor: function(){
      if(!this.colorIndex) {
        this.colorIndex = 0;
      }
      if(this.colorIndex >= chartColors.length) {
        this.colorIndex = 0;
      }
      return chartColors[this.colorIndex++];
    },
    async init() {

      this.initializing = true;
      const i = await this.loadInstrument(this.instrumentId);
      this.colors[i.id] = "#FE6C2D";
      const childrenMap = {};
      if(i.children) {
        i.children.forEach(c => {
          childrenMap[c.id] = c;
          this.colors[c.id] = this.generatorColor();
          c.visible = true;
        });
      }
      i.childrenMap = childrenMap;
      
      this.item = i;
      this.title = this.item.name;
      this.initializing = false;
    },
    generateReport() {

    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <div>   
            <h4 class="mb-0">
              <span class="title-content ">
                <span class="mr-2"> {{ title }}</span>
                
                <span v-if="loading" class="ml-2"><i class="fa fa-spin fa-spinner"/></span>
              </span>
            </h4>
            <span class="badge badge-danger mr-2" v-if="item && item.deletedAt">Instrument was removed</span>
            <a v-if="item && !item.WebUrl" href="http://www.c8-technologies.com">http://www.c8-technologies.com</a>
            <a v-if="item && item.WebUrl" :href="item.WebUrl">{{item.WebUrl}}</a> 
          </div>
        </div>
      </div>
    </div>
    <div v-if="!initializing">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-right">
            <b-form-checkbox v-model="normalised" switch class="mr-1" style="display:inline-block;">
            </b-form-checkbox>
            Normalised performance to 10 % annual volatility
          </div>
        </div>
      </div>

      <div class="row  mb-4">
        <div class="col-xl-8">
          <performance :instrument="item" :normalized="normalised" :colors="colors"></performance>
        </div>
        <div class="col-xl-4">
          <main-stat :instrument="item" :normalized="normalised"></main-stat>
        </div>
      </div>
      <!-- <div class="row" v-if="item.children.length > 0">
        <div class="col-xl-12">
          <composition :instrument="item" :normalized="normalised"></composition>
        </div>
      </div> -->
      <div class="row">
        <div class="col-xl-12">
          <monthly-performance :instrument="item" :normalized="normalised"></monthly-performance>
        </div>
      </div> -->
      <!-- <div class="row">
        <div class="col-xl-12">
          <weights :instrument="item" :normalized="normalised" :colors="colors"></weights>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <last-correlation :instrument="item"></last-correlation>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <all-correlation :instrument="item"></all-correlation>
        </div>
      </div>
      
      <div class="row">
        <div class="col-xl-12">
          <crises-chart :instrument="item"></crises-chart>
        </div>
      </div> -->
    </div>
  </Layout>
</template>