<script>


export default {
 
  props: {
    noCard: {
      type: Boolean,
      required: false
    },
    instrument: {
      type: Object,
      required: true
    },
    normalized:{
      type: Boolean,
      required: true
    },
    showOnlyFirst: {
      type: Boolean,
      required: false
    },
    firstTitle: {
      type: String,
      required: false
    }
  },
  computed: {
    values (){
      return this.normalized ? this.stat.normalizedValues  || {}: this.stat.values || {};
    },
    compValues () {
      return this.normalized ? this.stat.normalizedComparisonValues || {} : this.stat.comparisonValues || {};
    },
    hasComp() {
      return this.stat.hasComparisonValues || false;
    },
    hasStat() {
      return (this.instrument.data.mainStatistic || {}).firstTitle;
    },
    stat () {
      return this.instrument.data.mainStatistic;
    }
  }
};
</script>

<template>
  <div  :class="{'card cardc8': !noCard}" style="height:100%; ">
    <div  :class="{'card-content': !noCard}">
      <div  :class="{'card-body': !noCard}">

        <!-- Header -->
        <div class="row"  v-if="!noCard">
          <div class="col">
            <h5 class="card-title">Statistics</h5>
          </div>
        </div>

        <div class="table-responsive" style="position: relative; ">
          <em class="text-muted" v-if="!hasStat">No data found...</em>
          <table v-if="hasStat"
            class="table table-centered table-stripped table-sm datatable table-hover dt-responsive nowrap"
            style="border-collapse: collapse; border-spacing: 0; width: 100%;"
          >
            <thead class="thead-dark">
              <tr>
                <th></th>
                <th>
                  <b v-if="!firstTitle">{{stat.firstTitle === 'C8' ? instrument.name : stat.firstTitle}}</b>
                  <b v-if="firstTitle">{{firstTitle}}</b>
                </th>
                <th v-if="hasComp && !showOnlyFirst"><b>{{stat.secondTitle}}</b></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Annualised Return</td>
                <td>{{values.annualisedReturn}}%</td>
                <td v-if="hasComp && !showOnlyFirst">{{compValues.annualisedReturn}}</td>
              </tr>
              <tr>
                <td>Realised Volatility</td>
                <td>{{values.realisedVolatility}}%</td>
                <td v-if="hasComp && !showOnlyFirst">{{compValues.realisedVolatility}}</td>
              </tr>
              <tr>
                <td>Downside Volatility</td>
                <td>{{values.downsideVolatility}}%</td>
                <td v-if="hasComp && !showOnlyFirst">{{compValues.downsideVolatility}}</td>
              </tr>
              <tr>
                <td>Max Drawdown</td>
                <td>{{values.maxDrawdown}}%</td>
                <td v-if="hasComp && !showOnlyFirst">{{compValues.maxDrawdown}}</td>
              </tr>
              <tr>
                <td>Winning months</td>
                <td>{{values.winningMonths}}%</td>
                <td v-if="hasComp && !showOnlyFirst">{{compValues.winningMonths}}</td>
              </tr>
              <tr>
                <td>Sharpe Ratio</td>
                <td>{{values.sharpeRatio}}</td>
                <td v-if="hasComp && !showOnlyFirst">{{compValues.sharpeRatio}}</td>
              </tr>
              <tr>
                <td>Sortino Ratio</td>
                <td>{{values.sortinoRatio}}</td>
                <td v-if="hasComp && !showOnlyFirst">{{compValues.sortinoRatio}}</td>
              </tr>
              <tr>
                <td>Calmar Ratio</td>
                <td>{{values.calmarRatio}}</td>
                <td v-if="hasComp && !showOnlyFirst">{{compValues.calmarRatio}}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </div>
 
</template>